import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import CommingSoon from "../../components/commingsoon";

function VisitePage() {
  return (
    <Layout>
      <SEO
        title="Visites virtuelles 3D Matterport - hôtels et gîtes"
      />
<div className="mx-auto max-w-screen-xl">
  <div className="mt-4 mx-auto max-w-screen-xl px-4 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
    <h1>La visite virtuelle pour les hôtels et gîtes</h1>
  </div>
  <CommingSoon/>

</div>
    </Layout>
  );
}

export default VisitePage;
